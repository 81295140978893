import { createApp, defineAsyncComponent } from "vue";
import { init as sentryInit, BrowserTracing } from "@sentry/vue";
import { siFetch } from "si-utils";
import { registerPlugins } from "@/plugins";
import { useFilters } from "@/store/filters";
import { useSorting } from "@/store/sorting";
import { useJobs } from "@/store/jobs";
import { useMeta } from "@/store/requestMeta";
import { useSubscription } from "@/store/subscription";
import "@/assets/soz-overrides.css";
import "@/assets/vuetify-overrides.css";


const {
  setFacets,
  setLocations,
  setSearchString,
  setDistance,
  setCrisis,
  setInitialFilters,
} = useFilters();
const { setInitialSortings } = useSorting();
const { setUser, setHash, setUid } = useSubscription();
const SearchApp = defineAsyncComponent(() =>
    import('@/SearchApp.vue')
)
const SubscriptionApp = defineAsyncComponent(() =>
    import('@/SubscriptionApp.vue')
)
const $app = document.querySelector("#app");
const state = JSON.parse($app.dataset.initialState) || {};
let app = null;

const initMocking = async () => {
  if (import.meta.env.MODE !== "development") return;

  console.log(
    "%c Using mock data",
    "background: #1c1917; color: #fb923c; font-size: 1.2rem; padding: 0.5rem;"
  );
  const { worker } = await import("./__mocks__/browser");
  worker.start();
};

const initSentry = () => {
  if (import.meta.env.MODE !== "production") return;

  sentryInit({
    app,
    dsn: "https://bee7e863f85d4dd6a8f4372dd5c7d66f@o4504610852438016.ingest.sentry.io/4505187939450880",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.3,
    release: "@sozialinfo/search@" + __APP_VERSION__,
  });
};

const initSearch = async () => {
  await initMocking();

  app = createApp(SearchApp);
  initSentry();

  const { setJobs } = useJobs();
  const { setMeta } = useMeta();

  setJobs(state.resultSet);
  setInitialSortings(state.sorting);
  setMeta({
    allResultsCount: state.allResultsCount,
    usedResultsPerPage: state.usedResultsPerPage,
    usedPage: state.usedPage,
  });
  state.user && setUser(state.user);
};

const initSubscription = async () => {
  await initMocking();

  app = createApp(SubscriptionApp);
  initSentry();

  state.user && setUser(state.user);
  state.hash && setHash(state.hash);
  state.uid && setUid(state.uid);
};

const init = async () => {
  if ($app.dataset.app === "search") {
    await initSearch();
  }

  if ($app.dataset.app === "subscription") {
    await initSubscription();
  }

  setFacets(state.facets);
  setLocations(state.locations);
  setSearchString(state.searchString);
  setDistance(state.distance);
  setCrisis(state.crisis);

  setInitialFilters();

  registerPlugins(app);

  app.mount($app);
};

init();
